import { IRepsonseWrapper } from ".";
import { SplitDataType } from "../containers/document-container/constants";
import { apiClient, withAuthHeaders, qs } from "./apiClient";

const getDocuments = async (reqObj: any): Promise<IDocumentsListRes> => {
  if (reqObj.searchTerm) {
    reqObj.limit = 0;
    reqObj.offset = 0;
  }
  const { data } = await apiClient.get(`/document-analytics`, {
    params: reqObj,
  });
  return data;
};

const getDocumentByUuid = async (
  documentUuid: string
): Promise<DocumentData> => {
  const { data } = await apiClient.get(`/file/document-review/${documentUuid}`);
  return data.data;
};

const getSignedUrlByFilepath = async (path: string) => {
  const { data } = await apiClient.get("/storage/signedUrl/readByName", {
    params: { path },
  });
  return data.data;
};

const updateStatus = async (
  sectionId: number,
  status: string = "",
  key?: string
) => {
  const { data } = await apiClient.put(
    `/section/feedback-status/${sectionId}`,
    {
      status,
      key,
    }
  );
  return data.data;
};

const getSectionsAndInsights = async (uuid?: string) => {
  const res = await apiClient.get<IRepsonseWrapper<SplitDataType[]>>(
    `/section/document/${uuid}`
  );
  return res.data;
};

export const documentService = {
  getSignedUrlByFilepath,
  getDocuments,
  getDocumentByUuid,
  updateStatus,
  getSectionsAndInsights,
};
