import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IQuestAns } from "../../interfaces/chat";
interface ISources {
  documentUUID: string;
  pages: {
    pageNo: number;
    offsets: {
      begin: number;
      end: number;
    }[];
  }[];
  fileName: string;
}

interface IQuesAnsInitialState {
  quesAns: IQuestAns[];
  currentSource: ISources;
}

export const quesAnsInitialState: IQuesAnsInitialState = {
  quesAns: [] as IQuestAns[],
  currentSource: {} as ISources,
};

export const quesAnsSlice = createSlice({
  name: "quesAns",
  initialState: quesAnsInitialState,
  reducers: {
    setQuestionsAnswers: (
      state: IQuesAnsInitialState,
      { payload }: PayloadAction<IQuestAns[]>
    ) => {
      state.quesAns = [...payload];
    },
    resetQuestionAnswerState: (state: IQuesAnsInitialState) => {
      state.quesAns = quesAnsInitialState.quesAns;
    },
    setCurrentSource: (
      state: IQuesAnsInitialState,
      { payload }: PayloadAction<ISources>
    ) => {
      state.currentSource = payload;
    },
  },
});

export const {
  setQuestionsAnswers,
  resetQuestionAnswerState,
  setCurrentSource,
} = quesAnsSlice.actions;
export default quesAnsSlice.reducer;
