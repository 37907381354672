import { Button, Modal } from "antd";
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { SplitDataItem } from "../../document-container/constants";
import {
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../../utils";
import { useAuthUser } from "../../../hooks";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import "./comments.scss";
import { commentService } from "../../../api/comment";
import { fetchData } from "pdfjs-dist";
import { documentService } from "../../../api";

type Props = {
  handleClose: () => void;
  visibility: boolean;
  sectionDetails: SplitDataItem;
  comments: PageComment[];
  fetchComments: (record: SplitDataItem, type: string) => Promise<void>;
  documentUuid: string;
  isLoading: boolean;
  type: string;
  getSections: () => Promise<void>;
};

export const CommentsModal = (props: Props) => {
  const {
    handleClose,
    visibility,
    sectionDetails,
    fetchComments,
    documentUuid,
    isLoading,
    comments,
    type,
    getSections,
  } = props;
  const { firstName, lastName } = useAuthUser();
  const [newComment, setNewComment] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editCommentValue, setEditCommentValue] = useState("");
  const [commentId, setCommentId] = useState(-1);

  const handleClickDelete = async (reqObj: {
    username: string;
    id: number;
  }) => {
    try {
      await commentService.deleteById(reqObj);
      openNotificationWithIcon("", "Comment deleted successfully", "success");
      fetchComments(sectionDetails, type);
      getSections();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddComment = async () => {
    try {
      await commentService.save({
        comment: newComment,
        username: `${firstName} ${lastName}`.trim(),
        sectionId: sectionDetails.sectionId,
        documentUuid,
        type,
      });
      openNotificationWithIcon("", "Comment added successfully", "success");
      fetchComments(sectionDetails, type);
      setNewComment("");
      getSections();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewComment(event.target.value);
  };

  const handleEditComment = (comment: PageComment) => {
    setEditMode(true);
    setEditCommentValue(comment.comment);
    setCommentId(comment.id);
  };

  const handleSaveComment = async () => {
    try {
      const dataWithUserInfo = {
        id: commentId,
        comment: editCommentValue,
        username: `${firstName} ${lastName}`.trim(),
      };
      await commentService.update(dataWithUserInfo);
      setEditMode(false);
      openNotificationWithIcon("", "Comment updated successfully", "success");
      fetchComments(sectionDetails, type);
    } catch (error) {
      console.log(error);
    }
  };

  const renderComments = () => {
    return comments.map((comment) => (
      <div className="single-comment" key={comment.id}>
        <div className="heading">
          <h5>{comment.createdBy}</h5>
        </div>
        <div className="comment-details">
          <span className="date-time">
            {getLocalDateTimeStringFromISO(comment.createdAt, false)}
          </span>
          <div className="content">
            <p>{comment.comment}</p>
            <div className="actions">
              {comment.createdBy === `${firstName} ${lastName}`.trim() ? (
                <div className="actions">
                  <EditIcon onClick={() => handleEditComment(comment)} />
                  <DeleteIcon
                    onClick={() =>
                      handleClickDelete({
                        id: comment.id,
                        username: comment.createdBy,
                      })
                    }
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Modal
      className="autonomizeModal comments"
      centered
      width={1100}
      open={visibility}
      footer={null}
      title={"User Comments"}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="flex addClient-title">
        <h1 className="mb-0">User Comments</h1>
      </div>
      <div className="section-details">
        <div className="section-details-item">
          <strong>Category</strong>
          <span>{sectionDetails.category}</span>
        </div>
        <div className="section-details-item">
          <strong>Section</strong>
          <span>{sectionDetails.section}</span>
        </div>
        <div className="section-details-item">
          <strong>Page Range</strong>
          <span>{sectionDetails.pageRange}</span>
        </div>
      </div>
      <div className="comments-container">
        <div className="comments-content">{renderComments()}</div>
        {editMode ? (
          <div className="edit-comment-container">
            <textarea
              className="ant-input css-dev-only-do-not-override-mzwlov ant-input-outlined"
              id="comment"
              rows={4}
              value={editCommentValue}
              onChange={(event) => setEditCommentValue(event.target.value)}
              autoFocus
            ></textarea>
            <div className="comment-actions">
              <Button className="fill pl-10" onClick={handleSaveComment}>
                Save Comment
              </Button>
              <Button className="fill" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div className="new-comment-container">
            <textarea
              className="ant-input css-dev-only-do-not-override-mzwlov ant-input-outlined"
              id="comment"
              rows={4}
              value={newComment}
              onChange={handleCommentChange}
              autoFocus
            ></textarea>
            <Button className="add-btn fill" onClick={handleAddComment}>
              Add Comment
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
