export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/create-password",
  CREATE_PASSWORD_SUCCESS: "/create-password-success",
  DOCUMENTS: "/documents",
  DOCUMENT: "/document",
  REDIRECTION: "/redirect",
  SETTINGS: "/settings",
};

export const PAGE_TITLE = [
  { route: PAGE_URL.DOCUMENT, title: "Document Insights", displayBackButton: true },
  { route: PAGE_URL.DOCUMENTS, title: "Document Insights", displayBackButton: false },
  { route: PAGE_URL.SETTINGS, title: "Settings", displayBackButton: true },
  {
    route: PAGE_URL.REDIRECTION,
    title: "Authentication",
    displayBackButton: false,
  },
];
