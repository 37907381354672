import { useCallback, useEffect, useState } from "react";
import { SorterResult } from "antd/es/table/interface";
import {
  STATUS_CODE,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
} from "../utils";
import moment from "moment";
import { usePagination } from "../hooks";
import { documentService } from "../api";

interface ColumnSort extends SorterResult<any> {
  sortOrder: "asc" | "desc" | null;
}

export const useFetchDocuments = () => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } =
    usePagination();
  const [sort, setSort] = useState({} as ColumnSort);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [total, setTotal] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const defaultFromDate = moment().add(-7, "d");
  const defaultToDate = moment();
  const defaultOverviewFromDate = moment("2023-08-01 00:00:00");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDocumentUUID, setSearchDocumentUUID] = useState("");
  const [status, setStatus] = useState("");
  const [keywords, setKeywords] = useState([] as Record<string, string>[]);

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: "asc" });
    else if (newSort.order === "descend")
      setSort({ ...newSort, sortOrder: "desc" });
    else setSort({ ...newSort, sortOrder: null });
  };

  const getDocumentsFilterObject = useCallback(
    (projectId?: number) => {
      let obj: IGetDocumentsRequest = {
        limit: pageSize,
        offset: getOffsetFromPageAndPageSize(page, pageSize),
        searchTerm: searchTerm,
        searchDocumentUUID: searchDocumentUUID,
        status: status,
        projectId: projectId,
      };
      if (sort.sortOrder && sort.field) {
        obj = {
          ...obj,
          sortField: sort.field.toString(),
          sortType: sort.sortOrder,
        };
      }
      return { ...obj };
    },
    [
      page,
      pageSize,
      sort.field,
      sort.sortOrder,
      fromDate,
      toDate,
      searchTerm,
      searchDocumentUUID,
      status,
    ]
  );

  const fetchDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await documentService.getDocuments(
        getDocumentsFilterObject()
      );
      setDocuments(data.data);
      setTotal(data.pagination.total ? data.pagination.total : 0);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [getDocumentsFilterObject]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    getDocumentsFilterObject,
    fetchDocuments,
    isDocumentsLoading: isLoading,
    setIsDocumentsLoading: setIsLoading,
    documents,
    totalDocuments: total,
    setDocuments,
    setTotalDocuments: setTotal,
    isAuthorized,
    defaultFromDate,
    defaultToDate,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    searchTerm,
    setSearchTerm,
    searchDocumentUUID,
    setSearchDocumentUUID,
    setStatus,
    field: keywords,
    defaultOverviewFromDate,
  };
};
