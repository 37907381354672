import React, { useEffect, useState } from "react";
import Table from "antd/lib/table";
import {
  FILE_STATUS,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../utils";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { PdfViewer } from "../pdf-viewer/PdfViewer";
import "./documents.scss";
import { useNavigate } from "react-router-dom";
import { AppPagination } from "../app-pagination/AppPagination";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import { documentService } from "../../api";
import { Input } from "antd/lib";
import { SearchIcon } from "../../assets/icons";
import { Button, Checkbox } from "antd";
import Search from "antd/lib/input/Search";
import { Loading } from "../../components";
import "../../assets/sass/layout/search.scss";

interface Props {
  projectId?: number;
  loading?: boolean;
}

interface IUploadedDocumentsDataType {
  key: React.Key;
  name: string;
  updatedOn: string;
  documentUUID: string;
  noOfPages: number;
  noOfPlaceholders: number;
  status: string;
  noOfReviews: number;
}

const initialSelectRows = {
  displayCheckBox: false,
  exporting: false,
  rows: [] as string[],
};

export const Documents = (props: Props) => {
  const { projectId } = props;
  const navigate = useNavigate();
  const {
    fetchDocuments,
    documents,
    isDocumentsLoading,
    paginationObj,
    totalDocuments,
    searchTerm,
    setSearchTerm,
  } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;

  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const [selectRows, setSelectRows] = useState(initialSelectRows);
  const [searchInput,setSearchInput]=useState("");

  const handlePreviewOnClick = async (
    path: string,
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    try {
      const { signedUrl } = await documentService.getSignedUrlByFilepath(path);
      setFileUrl(signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err?.message, "error");
    }
  };

  const handleClickTableRow = (docId: string, checked: boolean) =>
    setSelectRows((prev) => ({
      ...prev,
      rows: checked
        ? [...prev.rows, docId]
        : prev.rows.filter((id) => id !== docId),
    }));

  const handleToggleSelectRows = () =>
    setSelectRows((prev) => ({
      ...prev,
      displayCheckBox: !prev.displayCheckBox,
    }));

  const getColumns = () => [
    {
      title: "File name",
      key: "name",
      dataIndex: "name",
      render: (_: string, record: any) => (
        <span className="row-name">
          {selectRows.displayCheckBox ? (
            <Checkbox
              onChange={(e) =>
                handleClickTableRow(record.documentUUID, e.target.checked)
              }
            />
          ) : null}
          <span onClick={() => navigate(`/document/${record.documentUUID}`)}>
            {record.name}
          </span>
        </span>
      ),
    },
    {
      title: "No. of Pages",
      key: "noOfPages",
      dataIndex: "noOfPages",
      sorter: (a: IUploadedDocumentsDataType, b: IUploadedDocumentsDataType) =>
        a.noOfPages - b.noOfPages,
    },
    {
      title: "Review Status",
      key: "reviewStatus",
      render: (text: string, record: IUploadedDocumentsDataType) => {
        return (
          <span className={`status status-${record.status.toLowerCase()}`}>
            {record.noOfReviews > 0 ? "REVIEWED" : "NOT REVIEWED"}
          </span>
        );
      },
    },
    {
      title: "Total Placeholders",
      key: "noOfPlaceholders",
      dataIndex: "noOfPlaceholders",
      sorter: (a: IUploadedDocumentsDataType, b: IUploadedDocumentsDataType) =>
        a.noOfPlaceholders - b.noOfPlaceholders,
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      render: (text: string, record: IUploadedDocumentsDataType) => {
        return (
          <span className={`status status-${record.status.toLowerCase()}`}>
            {record.status}
          </span>
        );
      },
    },
    {
      title: "Preview",
      render: (text: any, value: any) => (
        <span
          className="dashboard-table-preview"
          onClick={(e) => handlePreviewOnClick(value.name, e)}
        >
          <EyeOutlined
            className="dashboard-table-preview-icon"
            title="Preview document"
          />
        </span>
      ),
    },
  ];

  useEffect(() => {
    fetchDocuments();
  }, [projectId, page, pageSize, searchTerm]);

  const data: IUploadedDocumentsDataType[] = documents.map((item, index) => ({
    updatedOn: getLocalDateTimeStringFromISO(item.updatedAt),
    name: item.filename,
    key: index,
    documentUUID: item.d_uuid,
    noOfPages: item.noOfPages,
    noOfPlaceholders: item.noOfPlaceholders,
    status: item.status,
    noOfReviews: item.noOfReviews
  }));

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleOnChange=(e:any)=>{
    const value=e.target.value.trim()
    setSearchInput(value);
    !value && handleSearch(value);
  }

  // const renderExportAnalysisButton = () => {
  //   if (!selectRows.displayCheckBox) {
  //     return (
  //       <Button className="fill" onClick={handleToggleSelectRows}>
  //         Bulk Export
  //       </Button>
  //     );
  //   }
  //   const handleExportAnalysis = async () => {
  //     if (!selectRows.rows.length) {
  //       return openNotificationWithIcon("", "No documents selected", "info");
  //     }
  //     setSelectRows((prev) => ({ ...prev, exporting: true }));
  //     try {
  //       const res = await documentService.exportAnalysis(selectRows.rows);
  //       const blob = await res.blob();
  //       var file = window.URL.createObjectURL(blob);
  //       window.location.assign(file); // TODO : add filename as doc name
  //       setSelectRows(initialSelectRows);
  //       openNotificationWithIcon(
  //         "",
  //         "Analysis Exported Successfully",
  //         "success"
  //       );
  //     } catch (error) {
  //       openNotificationWithIcon("", "Error exporting analysis", "error");
  //       console.log(error);
  //     } finally {
  //       setSelectRows((prev) => ({ ...prev, exporting: false }));
  //     }
  //   };

  //   return (
  //     <>
  //       <Button
  //         className="fill"
  //         onClick={handleExportAnalysis}
  //         loading={selectRows.exporting}
  //       >
  //         Export Analysis
  //       </Button>
  //       <Button
  //         className="outline"
  //         onClick={() => setSelectRows(initialSelectRows)}
  //       >
  //         Cancel
  //       </Button>
  //     </>
  //   );
  // };

  return (
    <div className="dashboard-table">
      <div className="table-actions">
        <Search
          className="record-search m-b"
          placeholder="Search by File Name"
          enterButton={
            isDocumentsLoading ? (
              <Loading tip="" />
            ) : (
              <Button icon={<SearchIcon />} />
            )
          }
          value={searchInput}
          onChange={(e)=>handleOnChange(e)}
          onSearch={(value) => handleSearch(value)}
          disabled={isDocumentsLoading}
          style={{ width: "40em" }}
        />
        <div className="header-actions">
          {/* {renderExportAnalysisButton()} */}
          <AppPagination
            className="tr paginationDiv"
            {...{
              showSizeChanger: true,
              current: page,
              total: totalDocuments,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
        </div>
      </div>
      <div className="dashboard-table__content">
        <Table
          columns={getColumns()}
          pagination={false}
          dataSource={data}
          loading={isDocumentsLoading}
          rowKey={(record) => record.documentUUID}
        />
        {fileUrl && (
          <div className="pdf-viewer dashboard-table__pdf-viewer">
            <PdfViewer
              pdf={fileUrl}
              displayPagination
              setCurrPage={setCurrentPage}
              currPage={currentPage}
              startPage={0}
              endPage={0}
            />
            <CloseOutlined
              className="pdf-viewer-closeicon"
              onClick={() => setFileUrl("")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
