import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FeedbackForm } from "./FeedbackForm";

type StateProps = {
  visibility: boolean;
  logId: number;
  qaId: string;
  feedbackType: number;
};
type DispatchProps = {
  handleClose: () => void;
};

export type PositiveFeedbackProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.FEEDBACK],
  logId: state.feedback.logId,
  qaId: state.feedback.qaId,
  feedbackType: state.feedback.feedbackType,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.FEEDBACK));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
