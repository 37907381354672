import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IFileFolderUploadInitialState {
  projectId: number;
  directoryPath: string;
  canFetchFiles: boolean;
  canFetchContainers: boolean;
  targetFileFolder: string;
  isEdit: boolean;
  continuationToken: string;
  currentPage: number;
}

export const fileFolderUploadInitialState: IFileFolderUploadInitialState = {
  projectId: -1,
  directoryPath: "",
  canFetchFiles: false,
  canFetchContainers: true,
  targetFileFolder: "",
  isEdit: false,
  continuationToken: "",
  currentPage: 1,
};

export const FileFolderUploadSlice = createSlice({
  name: "fileFolderUpload",
  initialState: fileFolderUploadInitialState,
  reducers: {
    updateDirectoryPath: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.directoryPath = payload;
    },

    updateCanFetchFiles: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.canFetchFiles = payload;
    },
    updateContinuationToken: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.continuationToken = payload;
    },
    updateCanFetchContainers: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.canFetchContainers = payload;
    },

    updateTargetFileFolder: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.targetFileFolder = payload;
    },

    updateFolderIsEditCheck: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEdit = payload;
    },

    updateProjectId: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<number>
    ) => {
      state.projectId = payload;
    },
    updateCurrentPage: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<number>
    ) => {
      state.currentPage = payload;
    },
  },
});

export const {
  updateCanFetchFiles,
  updateDirectoryPath,
  updateFolderIsEditCheck,
  updateTargetFileFolder,
  updateCanFetchContainers,
  updateProjectId,
  updateContinuationToken,
  updateCurrentPage,
} = FileFolderUploadSlice.actions;

export default FileFolderUploadSlice.reducer;
