import React, { useState } from "react";
import { Button, Progress } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { AppPagination } from "../app-pagination/AppPagination";
import "./pdf-content.scss";
import { useDispatch } from "react-redux";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { updateTargetFileFolder } from "../../store/file-folder-upload/slice";
import { FullscreenOutlined } from "@ant-design/icons";
import { useDocumentReview } from "../../pages/Document";
import {
  PaginationLeftArrowIcon,
  PaginationRightArrowIcon,
} from "../../assets/icons";
import { updateCurrentPage } from "../../store/file-folder-upload/slice";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  pageInfo?: {
    width?: number;
    height?: number;
    scale?: number;
  };
  displayPagination?: boolean;
  currPage: number;
  setCurrPage: (page: number) => void;
  pdf: any;
  className?: string;
  noExpand?: boolean;
  startPage?: number;
  endPage?: number;
}

export const PdfViewer = (props: Props) => {
  const {
    pdf,
    pageInfo,
    displayPagination,
    currPage,
    setCurrPage,
    startPage,
    endPage,
  } = props;
  const [totalPages, setTotalPages] = useState(0);
  const [loadSuccess, setLoadSuccess] = useState(false);
  const { setPdfData } = useDocumentReview();
  const [loadingProgress, setLoadingProgress] = useState({
    loaded: 0,
    total: 100,
  });
  const dispatch = useDispatch();
  const displayBottomPagination = displayPagination && loadSuccess;

  const onDocumentLoadSuccess = (params: any) => {
    const { numPages } = params;
    setPdfData((prev) => ({ ...prev, totalPages: numPages }));
    setTotalPages(numPages);
    setLoadSuccess(true);
  };

  const handleClickExpand = () => {
    dispatch(updateCurrentPage(currPage));
    dispatch(openModal(MODAL_TYPE.EXPAND_PDF));
    dispatch(updateTargetFileFolder(pdf));
  };

  return (
    <div className={`e-pdf e-content ${props.className}`}>
      {displayBottomPagination && (
        <div className="flex-center e-pdf-pagination">
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              icon={<PaginationLeftArrowIcon />}
              onClick={() => setCurrPage(currPage - 1)}
              disabled={currPage === 1}
            >
              Previous Page
            </Button>
            <span style={{ margin: "7px 10px" }}>
              {startPage !== 0 && endPage !== 0
                ? `Page ${currPage} of ${totalPages}`
                : `Page ${currPage} of ${totalPages}`}
              {}
            </span>
            <Button
              onClick={() => setCurrPage(currPage + 1)}
              disabled={currPage === (totalPages || 1)}
            >
              Next Page
              <span style={{ marginLeft: "8px" }}>
                <PaginationRightArrowIcon />
              </span>
            </Button>
          </div>
          {!props.noExpand ? (
            <FullscreenOutlined
              className="expand-icon"
              onClick={handleClickExpand}
            />
          ) : (
            ""
          )}
        </div>
      )}
      <Document
        className={`pdf ${props.noExpand ? "expanded" : ""}`}
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <Progress
            percent={Math.ceil(
              (loadingProgress.loaded * 100) / loadingProgress.total
            )}
          />
        }
        onLoadProgress={(e) => setLoadingProgress(e)}
      >
        <Page
          pageNumber={currPage}
          height={pageInfo?.height}
          width={pageInfo?.width || 500}
          scale={pageInfo?.scale}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  );
};
